<template>
  <div class="storeQrcdoe">
    <div class="box">
      <el-form label-width="200px" label-position="left">
        <!--  -->
        <el-form-item label="">
          <el-image v-if="storeQrcode" :src="storeQrcode"></el-image>
          <el-button v-else type="primary" @click="onOpenQrcode" size="small"
            >开通门店二维码</el-button
          >
        </el-form-item>
      </el-form>

      <div class="stores-openid">
        <el-input :value="storesOpenid" readonly></el-input>
      </div>

      <div class="tips">
        <span
          >开通微助理后，不需要每年交300元公众号认证费到腾讯公司，也可以实现：</span
        >
        <span>1、顾客消费后接收到消费通知；</span>
        <span>2、顾客可通过小程序查询卡金，次卡、历史消费......等等；</span>
        <span
          >3、顾客预约、领优惠劵、门店展示、积分兑换商城、连接wifi......等等；</span
        >
        <span>4、公众号群发通知。</span>
      </div>
    </div>
  </div>
</template>

<script>
import Setting from "@/api/setting.js";
export default {
  data() {
    return {
      storeQrcode: "",
      storesOpenid: "",
    };
  },
  mounted() {
    this.queryData();
  },
  methods: {
    async queryData() {
      try {
        let { data } = await Setting.storeQrcode({}, "status");
        if (data) {
          this.storeQrcode = data.storeQrcode
            ? "data:image/jpg;base64," + data.storeQrcode
            : "";
          this.storesOpenid = data.storeOpenId;
        }
      } catch (err) {
        console.log(err);
      }
    },

    async onOpenQrcode() {
      try {
        let { errcode } = await Setting.storeQrcode({}, "enable");
        if (errcode == 0) {
          this.$message({
            message: "开通成功",
            type: "success",
          });
          this.queryData();
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  display: flex;
  align-items: center;
  flex-direction: column;
  .el-form {
    width: 720px;
    margin-top: 20px;
    margin: 0 auto;
    .el-input {
      width: 100%;
    }

    .el-image {
      width: 200px;
      height: 200px;
    }
  }
  p,
  span {
    color: #8492a6;
    line-height: 25px;
  }

  .stores-openid {
    position: relative;
    left: -50px;

    .el-input {
      min-width: 400px;
    }
  }

  .tips{
    .flex-col();
    align-items: unset;
    margin-top: 20px;
  }
}
</style>
